import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	User,
	UserPermission,
	UserRole,
	UserSchema,
} from '@/schemas/user-schema.ts';
import * as Sentry from '@sentry/react';
import { CredentialResponse } from 'google-one-tap';
import postHogService from '@services/posthog-service.ts';
import themeStore from '@store/theme-store.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import { UserInterests } from '@/enums/user-interests.ts';
import { BaseStore } from '@store/base-store.ts';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const sessionUrl = `${baseUrl}/api/session`;
const accountUrl = `${baseUrl}/api/account`;

enum AUTH_STATE {
	PENDING = 'pending',
	AUTHENTICATED = 'authenticated',
	NOT_AUTHENTICATED = 'not_authenticated',
}

const SimulatedPermissions: Record<UserRole, UserPermission[]> = {
	[UserRole.ADMIN]: [
		UserPermission.EDIT_TAG,
		UserPermission.LIST_USERS,
		UserPermission.EDIT_USER_ROLE,
		UserPermission.EDIT_GAME_CREDENTIALS,
		UserPermission.EDIT_PLACE,
		UserPermission.VIEW_CODE_LIBRARY,
	],
	[UserRole.EDITOR]: [UserPermission.EDIT_TAG, UserPermission.EDIT_PLACE],
	[UserRole.VIEWER]: [],
};

class AuthStore implements BaseStore {
	private _user: User | null = null;
	private _isAuthenticated: AUTH_STATE = AUTH_STATE.PENDING;
	private _recentlyLoggedOut = false;
	private _simulatedRole: UserRole | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	reset() {
		runInAction(() => {
			this._user = null;
			this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
			this._recentlyLoggedOut = false;
			this._simulatedRole = undefined;
		});
	}

	async restoreSession() {
		try {
			const response = await httpFetch.GET(sessionUrl, true, true);
			if (response.ok) {
				const user = UserSchema.parse(await response.json());

				runInAction(() => {
					this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
					this._user = user;
					Sentry.setUser({ id: this._user.id });
					void postHogService.identify(this._user);
					themeStore.restoreFromLocalStorage(user.id);
					navSidebarStore.restoreFromLocalStorage(user.id);
				});
			} else {
				runInAction(() => {
					this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
					this._user = null;
				});
			}
		} catch (_) {
			runInAction(() => {
				this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
				this._user = null;
			});
		}
	}

	async login(email: string, password: string) {
		const response = await httpFetch.POST(sessionUrl, {
			email,
			password,
		});

		if (response.ok) {
			await runInAction(async () => {
				this._recentlyLoggedOut = false;
				this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
				await this.restoreSession();
			});
		}

		return response.ok;
	}

	async register(
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		interests: UserInterests[],
		type: string
	) {
		const userInterests: Record<string, boolean> = {};
		interests.forEach((interest) => {
			userInterests[interest] = true;
		});

		const response = await httpFetch.POST(accountUrl, {
			first_name: firstName,
			last_name: lastName,
			email,
			password,
			interests: userInterests,
			occupation: type,
		});

		return response.ok;
	}

	async update(interests: UserInterests[], occupation: string) {
		const userInterests: Record<string, boolean> = {};
		interests.forEach((interest) => {
			userInterests[interest] = true;
		});

		const response = await httpFetch.PUT(`${accountUrl}/profile`, {
			interests: userInterests,
			occupation,
		});

		return response.ok;
	}

	async googleLogin(credentialResponse: CredentialResponse) {
		const response = await httpFetch.POST(
			`${baseUrl}/api/auth/google/token`,
			{
				id_token: credentialResponse.credential,
			}
		);

		if (response.ok) {
			await runInAction(async () => {
				this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
				await this.restoreSession();
			});
		}
	}

	async logOut() {
		const response = await httpFetch.DELETE(sessionUrl);

		if (response.ok) {
			runInAction(() => {
				this._recentlyLoggedOut = true;
				this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
				postHogService.reset();
				themeStore.setToDefault();
				navSidebarStore.setOpen(false);
			});
		}
	}

	get sessionRestored() {
		return this._isAuthenticated !== AUTH_STATE.PENDING;
	}

	get isLoggedIn() {
		return this._isAuthenticated === AUTH_STATE.AUTHENTICATED;
	}

	async isAuthenticated() {
		return await this.checkIfAuthenticated();
	}

	get user() {
		if (this._simulatedRole && this._user) {
			return {
				...this._user,
				role: this._simulatedRole,
			};
		}

		return this._user;
	}

	get recentlyLoggedOut() {
		return this._recentlyLoggedOut;
	}

	havePermission(permission: UserPermission) {
		if (!this._user?.permissions) {
			return false;
		}

		if (this._simulatedRole) {
			return SimulatedPermissions[this._simulatedRole].includes(
				permission
			);
		}

		return this._user?.permissions?.includes(permission);
	}

	haveUserRole(roles: UserRole | UserRole[]) {
		if (!this._user) {
			return false;
		}

		const role = this._simulatedRole ?? this._user.role;

		if (Array.isArray(roles)) {
			return roles.includes(role);
		} else {
			return roles === role;
		}
	}

	get canSimulateRole() {
		return this._user?.role === UserRole.ADMIN;
	}

	get roleSimulated() {
		return !!this._simulatedRole;
	}

	simulateRole(simulatedRole: UserRole) {
		if (!this.canSimulateRole) {
			return;
		}

		runInAction(() => {
			this._simulatedRole = simulatedRole;
		});
	}

	private async checkIfAuthenticated(): Promise<boolean> {
		switch (this._isAuthenticated) {
			case AUTH_STATE.PENDING:
				await new Promise((resolve) => setTimeout(resolve, 250));
				return this.checkIfAuthenticated();
			case AUTH_STATE.AUTHENTICATED:
				return true;
			case AUTH_STATE.NOT_AUTHENTICATED:
				return false;
		}
	}
}

const authStore = new AuthStore();
export default authStore;
