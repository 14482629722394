import { makeAutoObservable, runInAction } from 'mobx';
import localStorageService from '@services/local-storage-service.ts';
import { BaseStore } from '@store/base-store.ts';

class NavSidebarStore implements BaseStore {
	private _open = false;
	private _minimal = false;

	reset() {
		runInAction(() => {
			this._open = false;
			this._minimal = false;
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	setOpen(isOpen: boolean) {
		runInAction(() => {
			this._open = isOpen;
		});
	}

	toggleOpen() {
		runInAction(() => {
			this._open = !this._open;
		});
	}

	setMinimal(minimal: boolean, userId?: number) {
		runInAction(() => {
			this._minimal = minimal;
		});

		if (!userId) {
			return;
		}

		localStorageService.set(`tgs-sb-m`, minimal ? 'true' : 'false', userId);
	}

	restoreFromLocalStorage(userId?: number) {
		if (!userId) {
			return;
		}

		runInAction(() => {
			this._minimal =
				localStorageService.get(`tgs-sb-m`, userId) === 'true';
		});
	}

	hide() {
		const href = window.location.href;
		return (
			href.endsWith('sign-in') ||
			href.endsWith('sign-up') ||
			href.endsWith('privacy-policy') ||
			href.endsWith('terms-of-use') ||
			href.endsWith('cookie-policy')
		);
	}

	get open() {
		return this._open;
	}

	get minimal() {
		return this._minimal;
	}
}

const navSidebarStore = new NavSidebarStore();
export default navSidebarStore;
