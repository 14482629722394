export enum PostHogEventType {
	PageView = '$pageview', // Override the built in page view event due to being a SPA.
	NavigateToLightdash = 'navigate_to_lightdash',
	NavigateToExperience = 'navigate_to_experience',
	ViewTag = 'view_tag',
	CompareExperiences = 'compare_experiences',
	DownloadBadgeStats = 'download_badge_stats',
	GenerateDeepLink = 'generate_deep_link',
}

export interface PostHogEventBase {
	type: PostHogEventType;
}

export interface PageView extends PostHogEventBase {
	type: PostHogEventType.PageView;
	$current_url: string;
}

export interface NavigateToLightdash extends PostHogEventBase {
	type: PostHogEventType.NavigateToLightdash;
	dashboard: string;
}

export interface NavigateToExperience extends PostHogEventBase {
	type: PostHogEventType.NavigateToExperience;
	from: string;
	experience: string;
	genre: string;
	sub_genre: string;
}

export interface ViewTag extends PostHogEventBase {
	type: PostHogEventType.ViewTag;
	tag: string;
}

export interface CompareExperiences extends PostHogEventBase {
	type: PostHogEventType.CompareExperiences;
	experience_1: string;
	experience_2: string;
}

export interface DownloadBadgeStats extends PostHogEventBase {
	type: PostHogEventType.DownloadBadgeStats;
	experience: string;
}

export interface GenerateDeepLink extends PostHogEventBase {
	type: PostHogEventType.GenerateDeepLink;
	link_type: string;
}

export type PostHogEvent =
	| PageView
	| NavigateToLightdash
	| NavigateToExperience
	| ViewTag
	| CompareExperiences
	| DownloadBadgeStats
	| GenerateDeepLink;
