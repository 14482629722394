import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { ErrorResponseSchema } from '@/schemas/error-response-schema.ts';
import authStore from '@store/auth-store.ts';

class HttpFetchService {
	async POST(
		url: string,
		body: object,
		silentError = false
	): Promise<Response> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		let response = await fetch(this.appendGuestParam(url), {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
			credentials: 'include',
		});

		// Handle rate limit retry logic
		response = await this.handleRateLimitRetry(response, () => {
			return this.POST(url, body, silentError);
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async PUT(
		url: string,
		body: object,
		silentError = false
	): Promise<Response> {
		const headers = {
			'content-type': 'application/json',
		};

		let response = await fetch(this.appendGuestParam(url), {
			method: 'PUT',
			headers,
			body: JSON.stringify(body),
			credentials: 'include',
		});

		// Handle rate limit retry logic
		response = await this.handleRateLimitRetry(response, () => {
			return this.PUT(url, body, silentError);
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async GET(
		url: string,
		includeCredentials = true,
		silentError = false,
		additionalHeaders?: HeadersInit
	): Promise<Response> {
		const headers: HeadersInit = additionalHeaders ?? {};

		const requestInit: RequestInit = {
			method: 'GET',
			credentials: includeCredentials ? 'include' : 'omit',
			headers,
		};

		let response = await fetch(this.appendGuestParam(url), requestInit);

		// Handle rate limit retry logic
		response = await this.handleRateLimitRetry(response, () => {
			return this.GET(
				url,
				includeCredentials,
				silentError,
				additionalHeaders
			);
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async DELETE(url: string, silentError = false): Promise<Response> {
		let response = await fetch(this.appendGuestParam(url), {
			method: 'DELETE',
			credentials: 'include',
		});

		// Handle rate limit retry logic
		response = await this.handleRateLimitRetry(response, () => {
			return this.DELETE(url, silentError);
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	private appendGuestParam(url: string) {
		if (!authStore.isLoggedIn) {
			const urlObj = new URL(url);

			// Ensure `?quest` is appended correctly
			if (!urlObj.searchParams.has('guest')) {
				urlObj.searchParams.append('guest', 'true');
			}

			return urlObj.toString();
		}

		return url;
	}

	private async responseError(response: Response) {
		const error = ErrorResponseSchema.parse(await response.json());
		toastStore.emit(error.error, ToastType.ERROR);
	}

	private async handleRateLimitRetry(
		response: Response,
		retryFunction: () => Promise<Response>
	): Promise<Response> {
		if (response.status === 429) {
			const retryAfter = response.headers.get('x-ratelimit-reset');

			if (retryAfter) {
				const currentTime = Math.floor(Date.now() / 1000); // Get current Unix timestamp
				let resetTime = parseInt(retryAfter, 10); // Parse the retry time from header

				if (isNaN(resetTime) || resetTime <= currentTime) {
					resetTime = currentTime + 3; // Retry in 3 seconds if not a valid reset time
				}

				const waitTime = resetTime - currentTime; // Calculate how long to wait in seconds

				// Wait for the required amount of time
				await new Promise((resolve) =>
					setTimeout(resolve, waitTime * 1000)
				);

				// Recursively call the retry function after waiting
				return retryFunction();
			}
		}

		return response;
	}
}

const httpFetch = new HttpFetchService();
export default httpFetch;
